import '@mrhenry/wp--bugsnag-config';

// polyfills for lit
import '@mrhenry/core-web/modules/CustomEvent';
import '@mrhenry/core-web/modules/DOMTokenList';
import '@mrhenry/core-web/modules/DOMTokenList.prototype.@@iterator';
import '@mrhenry/core-web/modules/DOMTokenList.prototype.forEach';
import '@mrhenry/core-web/modules/DOMTokenList.prototype.replace';
import '@mrhenry/core-web/modules/DocumentFragment';
import '@mrhenry/core-web/modules/DocumentFragment.prototype.append';
import '@mrhenry/core-web/modules/Element.prototype.append';
import '@mrhenry/core-web/modules/Element.prototype.classList';
import '@mrhenry/core-web/modules/Element.prototype.getAttributeNames';
import '@mrhenry/core-web/modules/Element.prototype.matches';
import '@mrhenry/core-web/modules/Element.prototype.remove';
import '@mrhenry/core-web/modules/Event';
import '@mrhenry/core-web/modules/HTMLTemplateElement';
import '@mrhenry/core-web/modules/MediaQueryList.prototype.addEventListener';
import '@mrhenry/core-web/modules/MutationObserver';
import '@mrhenry/core-web/modules/Node.prototype.contains';
import '@mrhenry/core-web/modules/NodeList.prototype.@@iterator';
import '@mrhenry/core-web/modules/NodeList.prototype.forEach';
import '@mrhenry/core-web/modules/console';
import '@mrhenry/core-web/modules/console.error';
import '@mrhenry/core-web/modules/console.log';
import '@mrhenry/core-web/modules/matchMedia';
import '@mrhenry/core-web/modules/requestAnimationFrame';
if ( 1 === parseInt( '0', 10 ) ) { // fix to force globalThis polyfill

	console.log( globalThis );
}
// polyfills for lit

import '@mrhenry/wp--autoplay-in-view';

import { PrivacyControls } from '@mrhenry/wp--mr-interactive';

PrivacyControls.setupPrivacyAwareEmbedders();
PrivacyControls.setupPrivacyAwareGTM();

import './modules/mr-age-gate-modal';
import './modules/mr-beer-teaser';
import './modules/mr-hover-label';
import './modules/mr-input-sink';
import './modules/mr-random-list-swapper';
import './modules/mr-request-device-orientation';
import './modules/mr-privacy-dialog';
import './modules/mr-scroll-to-top';
import './modules/mr-superett-accordion';
import './modules/mr-youtube-embed-notice';

import './modules/sttupid/sttupid-strike-through';
